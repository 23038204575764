import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

export const USERS_URL = `${API_URL}/users`

export function getUser(userId: number, token: string) {
    return axios({
        method: 'get',
        url: `${USERS_URL}/${userId}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
}

export function getUsers(token: string) {
    return axios({
        method: 'get',
        url: `${USERS_URL}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
}

export function getUsersFromUrl(url: string, token: string) {
    return axios({
        method: 'get',
        url: `${USERS_URL}?${url}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
}

export function createUser(
    email: string,
    firstName: string,
    lastName: string,
    password: string,
    passwordConfirmation: string,
    roles: string[],
    token: string
) {
    return axios({
        method: 'post',
        url: USERS_URL,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: {
            email,
            firstName,
            lastName,
            password,
            passwordConfirmation,
            roles
        }
    })
}

export function setUserAvatar(
    userId: number,
    requestBody: FormData,
    token: string
) {
    return axios.post(`${USERS_URL}/${userId}/avatar`, requestBody,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            }
        }
    )
}

export function deleteUser(
    userId: number,
    token: string
) {
    return Promise.reject('User deletion disabled temporarily');
    /*// disabled until database foreign keys audited and turned to no action - took user off of the connections
     they made, which was not desired, and hard deleted the record. Leaving code as an example for the redux work.
    return axios({
        method: 'delete',
        url: `${USERS_URL}/${userId}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
     */
}

export function updateUser(
    userId: number,
    firstName: string,
    lastName: string,
    isEmailVerified: boolean,
    isLockedOut: boolean,
    roles: string[],
    token: string
) {
    return axios({
        method: 'patch',
        url: `${USERS_URL}/${userId}`,
        headers: {
            'Authorization': `Bearer ${token}`
        },
        data: {
            firstName,
            lastName,
            isEmailVerified,
            isLockedOut,
            roles
        }
    })
}
