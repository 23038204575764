import { format } from 'date-fns';
import { isEmpty, size } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import { getEmployeeProfileRoute } from '../../../features/employee/utils/employee.utility';
import { teamSlice } from '../../../features/team/team.api';
import { RootState } from '../../../setup';
import { PageTitle } from '../../../_library/layout/core';
import { Attendance } from '../../modules/attendance/models/Attendance.models';
import { DateFormatUsingDateFns } from '../../modules/date/DateFormat.const';
import { parseYmdDatesToDateObject } from '../../modules/date/utils/DateHelpers';
import { Discipline, DisciplineStatusForHumansEnum } from '../../modules/discipline/models/Discipline.models';
import { UserRoleEnum } from '../../modules/user/models/User.model';
import { EmployeeType, TeamType } from '../../types/EmployeeType';
import { Link, useNavigate } from 'react-router-dom';
import { DisciplineType } from '../../types/DisciplineType';
import { KTSVG } from '../../../_library/helpers';
import { AllFilterType } from '../../types/FilterType';
import { ChangeTeamModal } from '../team/components/ChangeTeamModal';
import * as search from './redux/SearchRedux';
import { AttendanceType } from '../../types/AttendanceType';
import { CoachingType } from '../../types/CoachingType';
import { RecognitionType } from '../../types/RecognitionType';
import './AdvancedSearch.scss';
import calendarIcon from '../../../images/icons/calendar.svg';
import { Employee } from '../../modules/employee/models/Employee.model';
import { recognitionModalSlice } from '../../../features/recognition/recognition-modal.slice';
import { ModalDisplayMode } from '../../../common/interfaces/modal.model';
import clsx from 'clsx';
import { AvatarSize } from '../../components/Avatar';
import { hasTeamMatch } from '../../../common/utilities/team.utility';
import { UserModel } from '../../models';
import { toast } from 'react-toastify';
import { EmployeeBadge } from '../../components/EmployeeBadge';

export function AdvancedSearch() {
	const dispatch = useDispatch();
	const allFilter: AllFilterType = useSelector<RootState>(({search}) => search.allFilter, shallowEqual) as AllFilterType;
	const employees: EmployeeType[] = useSelector<RootState>(({search}) => search.employees, shallowEqual) as EmployeeType[];
	const disciplines: DisciplineType[] = useSelector<RootState>(({search}) => search.disciplines, shallowEqual) as DisciplineType[];
	const attendances: AttendanceType[] = useSelector<RootState>(({search}) => search.attendances, shallowEqual) as AttendanceType[];
	const coachings: CoachingType[] = useSelector<RootState>(({search}) => search.coachings, shallowEqual) as CoachingType[];
	const recognitions: RecognitionType[] = useSelector<RootState>(({search}) => search.recognitions, shallowEqual) as RecognitionType[];
	const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel;
	const navigate = useNavigate();
	const [start, setStart] = useState<Date | null>(new Date());
	const [end, setEnd] = useState<Date | null>(null);
	const cancelButtonRef = useRef<HTMLButtonElement>(null);
	const [employeesOnTeam, setEmployeesOnTeam] = useState<number[]>([]);
	const [selectedEmployee, setSelectedEmployee] = useState<EmployeeType>();
	const { data: teams } = teamSlice.useGetAllTeamsQuery();
	const changeTeamButtonRef = useRef<HTMLButtonElement>(null);

	useEffect(() => {
		const employeeIDs: number[] = [];
		employees.forEach((employee: EmployeeType) => {
			if (user.roles?.includes('SuperAdmin')) {
				return employeeIDs.push(employee.id);
			}
			if (!!employee.teams && hasTeamMatch(employee.teams, user.teams)) {
				return employeeIDs.push(employee.id);
			}
		});
		setEmployeesOnTeam(employeeIDs);
	}, [employees]);

	const getPreviousMonday = () => {
		var date = new Date();
		var day = date.getDay();
		var prevMonday = new Date();
		if (date.getDay() === 0) {
			prevMonday.setDate(date.getDate() - 7);
		} else {
			prevMonday.setDate(date.getDate() - (day - 1) - 7);
		}
		return prevMonday;
	};

	const getPreviousSunday = () => {
		const date = new Date();
		var prevSunday = new Date();
		prevSunday.setDate(date.getDate() - date.getDay());
		return prevSunday;
	};

	function getFirstDayPreviousMonth() {
		const date = new Date();

		return new Date(date.getFullYear(), date.getMonth() - 1, 1);
	}

	function getLastDayPreviousMonth() {
		const date = new Date();

		return new Date(date.getFullYear(), date.getMonth(), 0);
	}

	const onRangeChange = (dates: (Date | null)[]) => {
		const [start, end] = dates;
		setStart(start);
		setEnd(end);
	};

	const onChangeTeamClick = (emp: EmployeeType) => {
		setSelectedEmployee(emp);
		setTimeout(() => {
			changeTeamButtonRef.current?.click();
		}, 0);
	};

	return (
		<>
			<PageTitle breadcrumbs={[]}>Advanced Search</PageTitle>
			<div className="advanced_search_container">
				<div className="result_wrapper">
					{employees.length > 0 && <h2 className="result_title">People ({employees.length})</h2>}
					{
						employees.map(employee => {
							return <div
								key={employee.id}
								className={clsx('py-3 d-flex flex-wrap col-12 align-items-center',
									'justify-content-lg-start justify-content-between bg-hover-lighten',
									{
										'cursor-not-allowed': !employeesOnTeam.includes(employee.id) || size(employee.teams) === 0,
										'cursor-pointer': employeesOnTeam.includes(employee.id) && size(employee.teams) > 0,
								})}
								title={size(employee.teams) === 0 ? 'Unable to access employee not on a team' : ''}
								onClick={(e) => {
									const url = getEmployeeProfileRoute(employee);
									if (isEmpty(url)) {
										return console.error(`Unable to navigate to employee as they aren't assigned to a team`);
									}
									if (employeesOnTeam.includes(employee.id)) {
										return navigate(url);
									}
									return console.error(`Unable to navigate to employee who does not have a team in common with you`);
								}}
							>
								<div className="col-6 col-lg-2">
									<EmployeeBadge
										employee={employee}
										size={AvatarSize.md} />
								</div>
								<div className="col-6 col-lg-2 d-flex justify-content-around justify-content-lg-start">
									<div>
										<img
											className="ms-4"
											src={calendarIcon}
											width="16"
											alt="" />
										<span className="p-3">{employee.lastConnectDate || 'None'}</span>
									</div>
								</div>
								<div className="col-12 col-lg-4 d-flex justify-content-around justify-content-lg-start">
									<button
										type="button"
										className="btn btn-outline btn-light-primary btn-active-primary"
										onClick={e => {
											e.preventDefault();
											e.stopPropagation();
											if (!!employee.teams && employee.teams.length < 1) {
												toast.error('Employee not assigned to a team! Employee must be assigned to a team to recognize.', {autoClose: 3500});
											} else {
												dispatch(recognitionModalSlice.actions.show({
													mode: ModalDisplayMode.Create,
													employee: (employee as unknown as Employee),
												}));
											}
										}}
									>
										Recognize
									</button>
									{user.roles?.includes(UserRoleEnum.SuperAdmin) && (
										<button
											type="button"
											className="btn btn-outline btn-light-secondary btn-active-secondary"
											onClick={e => {
												e.preventDefault();
												e.stopPropagation();
												onChangeTeamClick(employee);
											}}
										>
											{size(employee.teams) > 0 ? 'Reassign' : 'Assign'}
										</button>
									)}
								</div>
							</div>;
						})
					}
					{disciplines.length > 0 &&
						<div className="result_title">Corrective Actions ({disciplines.length})</div>}
					{
						disciplines.map(discipline => {
							return <div
								key={discipline.id}
								className="result_connection_item d-sm-flex align-items-center mb-3">
								<div className="result_item_left d-xl-flex flex-grow-1 justify-content-between me-8">
									<div className="automation_name">
										{discipline.step.name}
									</div>
									<div className="connection_type pe-5">
										{discipline.step.type}
									</div>
									<div className="team_department">
										{DisciplineStatusForHumansEnum[discipline.status]}
									</div>
									<div className="connection_comment">
										{discipline.supervisorNote}
									</div>
								</div>
								<div className="result_item_right d-flex align-items-center mt-5 mt-sm-0">
									<div className="item_date d-flex align-items-center">
										<img
											src={calendarIcon}
											width="16"
											alt="" />
										<span>{format(parseYmdDatesToDateObject((discipline as unknown as Discipline).date), DateFormatUsingDateFns.ThreeMonthTwoDay)}</span>
									</div>
									<EmployeeBadge
										employee={discipline.employee}
										size={AvatarSize.md}
										team={discipline.employee?.teams?.[0]}
									/>
									{/* No route/page was ever built */}
									{/*<Link*/}
									{/*	className="btn btn-sm btn_detail"*/}
									{/*	to={`/connection/${discipline.id}`}>*/}
									{/*	<i className="bi bi-three-dots fs-5"></i>*/}
									{/*</Link>*/}
								</div>
							</div>;
						})
					}
					{attendances.length > 0 && <div className="result_title">Attendances ({attendances.length})</div>}
					{
						attendances.map(attendance => {
							return <div
								key={attendance.id}
								className="result_connection_item d-sm-flex align-items-center mb-3">
								<div className="result_item_left d-xl-flex flex-grow-1 justify-content-between me-8">
									<div className="automation_name">
										Attendance
									</div>
									<div className="connection_type">
										{attendance.reason.content}
									</div>
									<div className="team_department">
									</div>
									<div className="connection_comment">
										{attendance.supervisorNote}
									</div>
								</div>
								<div className="result_item_right d-flex align-items-center mt-5 mt-sm-0">
									<div className="item_date d-flex align-items-center">
										<img
											src={calendarIcon}
											width="16"
											alt="" />
										<span>{format(parseYmdDatesToDateObject((attendance as unknown as Attendance).date), DateFormatUsingDateFns.ThreeMonthTwoDay)}</span>
									</div>
									<EmployeeBadge
										employee={attendance.employee}
										size={AvatarSize.md}
										team={attendance.employee?.teams?.[0]}
									/>
									{/* No route/page was ever built */}
									{/*<Link*/}
									{/*	className="btn btn-sm btn_detail"*/}
									{/*	to={`/connection/${attendance.id}`}>*/}
									{/*	<i className="bi bi-three-dots fs-5"></i>*/}
									{/*</Link>*/}
								</div>
							</div>;
						})
					}
					{coachings.length > 0 && <div className="result_title">Coachings ({coachings.length})</div>}
					{
						coachings.map(coaching => {
							return <div
								key={coaching.id}
								className="result_connection_item d-sm-flex align-items-center mb-3">
								<div className="result_item_left d-xl-flex flex-grow-1 justify-content-between me-8">
									<div className="automation_name">
										Coaching
									</div>
									<div className="connection_type">
										{coaching.category.name}
									</div>
									<div className="team_department">
									</div>
									<div className="connection_comment">
										{coaching.comment}
									</div>
								</div>
								<div className="result_item_right d-flex align-items-center mt-5 mt-sm-0">
									<div className="item_date d-flex align-items-center">
										<img
											src={calendarIcon}
											width="16"
											alt="" />
										<span>{format(parseYmdDatesToDateObject(coaching.date), DateFormatUsingDateFns.ThreeMonthTwoDay)}</span>
									</div>
									<EmployeeBadge
										employee={coaching.employee}
										size={AvatarSize.md}
										team={coaching.employee?.teams?.[0]}
									/>
									{/* No route/page was ever built */}
									{/*<Link*/}
									{/*	className="btn btn-sm btn_detail"*/}
									{/*	to={`/connection/${coaching.id}`}>*/}
									{/*	<i className="bi bi-three-dots fs-5"></i>*/}
									{/*</Link>*/}
								</div>
							</div>;
						})
					}
					{recognitions.length > 0 && <div className="result_title">Recognitions ({recognitions.length})</div>}
					{
						recognitions.map(recognition => {
							return <div
								key={recognition.id}
								className="result_connection_item d-sm-flex align-items-center mb-3">
								<div className="result_item_left d-xl-flex flex-grow-1 justify-content-between me-8">
									<div className="automation_name">
										Recognition
									</div>
									<div className="connection_type">
										{recognition.category.name}
									</div>
									<div className="team_department">
									</div>
									<div className="connection_comment">
										{recognition.message}
									</div>
								</div>
								<div className="result_item_right d-flex align-items-center mt-5 mt-sm-0">
									<div className="item_date d-flex align-items-center">
										<img
											src={calendarIcon}
											width="16"
											alt="" />
										<span>{format(parseYmdDatesToDateObject(recognition.date), DateFormatUsingDateFns.ThreeMonthTwoDay)}</span>
									</div>
									<EmployeeBadge
										employee={recognition.employee}
										size={AvatarSize.md}
										team={recognition.employee?.teams?.[0]}
									/>
									{/* No route/page was ever built */}
									{/*<Link*/}
									{/*	className="btn btn-sm btn_detail"*/}
									{/*	to={`/connection/${recognition.id}`}>*/}
									{/*	<i className="bi bi-three-dots fs-5"></i>*/}
									{/*</Link>*/}
								</div>
							</div>;
						})
					}
				</div>
			</div>
			{!!selectedEmployee && !!teams && size(teams) > 0 && (
				<ChangeTeamModal
					employee={selectedEmployee}
					teams={teams}
					refresh={(newTeam) => {
						let msg = `${selectedEmployee.firstName} ${selectedEmployee.lastName} has been unassigned from all teams`;
						if (newTeam !== null) {
							msg = `${selectedEmployee.firstName} ${selectedEmployee.lastName} has been reassigned to ${newTeam.name}`;
						}
						toast.success(msg);
						const updatedEmps = employees.map(emp => {
							if (emp.id === selectedEmployee.id) {
								emp.teams = ([newTeam] as TeamType[]);
							}
							return emp;
						});
						dispatch(search.searchActions.setEmployees(updatedEmps));
						setSelectedEmployee(undefined);
					}}
				/>
			)}
			<div
				className="modal fade"
				tabIndex={-1}
				id="search_date_range_modal">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Range</h5>
							<div
								className="btn btn-icon btn-sm btn-active-light-primary ms-2"
								data-bs-dismiss="modal"
								aria-label="Close"
							>
								<KTSVG
									path="/media/icons/duotune/arrows/arr061.svg"
									className="svg-icon svg-icon-2x"
								/>
							</div>
						</div>
						<div className="modal-body d-flex justify-content-center flex-column align-items-center">
							<div className="d-flex align-items-center justify-content-around w-100 mb-10">
								<input
									type="text"
									value={!!start ? format(start, DateFormatUsingDateFns.PerfectDate) : ''}
								/>
								<input
									type="text"
									value={!!end ? format(end, DateFormatUsingDateFns.PerfectDate) : ''}
								/>
							</div>
							<DatePicker
								selected={start}
								onChange={onRangeChange}
								startDate={start}
								endDate={end}
								selectsRange
								inline
							/>
						</div>
						<div className="modal-footer">
							<button
								ref={cancelButtonRef}
								type="button"
								className="btn btn-light"
								data-bs-dismiss="modal"
							>
								Cancel
							</button>
							<button
								type="button"
								className="btn btn-primary"
								onClick={() => {
									if (!!start && !!end) {
										dispatch(search.searchActions.setAllFilter({
											...allFilter,
											start: start,
											end: end,
										}));
										cancelButtonRef.current?.click();
									}
								}}
							>
								Save
							</button>
						</div>
					</div>
				</div>
			</div>
			<button
				type="button"
				ref={changeTeamButtonRef}
				data-bs-toggle='modal'
				data-bs-target='#change_team_modal'
				className="d-none"
				/>
		</>
	);
}
