import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { toAbsoluteUrl } from '../../../../_library/helpers'
import { FilterButton } from '../../../components/FilterButton';
import { removeDiscipline } from '../../../CRUD/DisciplineCRUD'
import { getPagination } from '../../../CRUD/TeamCRUD'
import { RootState } from '../../../../setup';
import { shallowEqual, useSelector } from 'react-redux';
import { UserModel } from '../../../models'
import { CorrectiveActionModal } from '../../../modules/discipline/components/CorrectiveActionModal';
import { DisciplineStatusForHumansEnum } from '../../../modules/discipline/models/Discipline.models';
import { Employee } from '../../../modules/employee/models/Employee.model';
import { Pagination } from '../../../components/Pagination'
import { DisciplineType } from '../../../types/DisciplineType'
import { ConnectionSortModel } from '../../../models/ConnectionSortModel';
import { ConnectionListFilterModal } from '../ConnectionListFilterModal';
import { SkeDialogDelete } from '../../../../common/components/dialog-delete';
import { toast } from 'react-toastify'


type Props = {
    className?: string
}

const DisciplinesList: React.FC<Props> = ({ className }) => {
    const token: string = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual) as string
    const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
    const [disciplines, setDisciplines] = React.useState<any>({ items: [] })
    const [sort, setSort] = React.useState<ConnectionSortModel>({ field: 'date', order: 'DESC' })
    const [selectedDiscipline, setSelectedDiscipline] = React.useState<DisciplineType | null>(null)
    const [startDate, setStartDate] = useState<string>(moment().subtract(1, 'years').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState<string>(moment().add(1, 'days').format('YYYY-MM-DD'));
    const [teamId, setTeamId] = useState(0)
    const [supervisorId, setSupervisorId] = useState(0)
    const [pageLimit, setPageLimit] = useState(24)
    const [currentPageNumber, setCurrentPageNumber] = useState(1)
    const viewModalRef = React.useRef<HTMLAnchorElement>(null)
    const [showNewDiscipline, setShowNewDiscipline] = useState<boolean>(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    const applyFilterView = (e: React.MouseEvent, start_date: string, end_date: string, teamIdFilter: number, supervisorIdFilter: number) => {
        setStartDate(start_date);
        setEndDate(end_date);
        setTeamId(teamIdFilter)
        setSupervisorId(supervisorIdFilter)

        // getPagination(`api/connections?limit=${pageLimit}&search=${inputSearch}&fromDate=${startDate}&toDate=${endDate}${status !== '' ? '&status=' + status : ''}`, token)

        getPagination(`api/disciplines?limit=${pageLimit}&isActionRequired=true&fromDate=${start_date}&toDate=${end_date}${teamIdFilter !== 0 ? '&teamId=' + teamIdFilter : ''}${supervisorIdFilter !== 0 ? '&creatorId=' + supervisorIdFilter : ''}&sort=${sort.order === 'DESC' ? '-' : ''}${sort.field}`, token)
            .then(({ data }) => {
                setDisciplines(data)
            })
            .catch(err => {
                console.log(err.response)
            })
    }

    const handlePrevious = () => {
        getPagination(disciplines.links.previous, token)
            .then(({ data }) => {
                setCurrentPageNumber(currentPageNumber - 1)
                setDisciplines(data)
            })
            .catch(err => {
                console.log(err.response)
            })
    }
    const handleNext = () => {
        getPagination(disciplines.links.next, token)
            .then(({ data }) => {
                setCurrentPageNumber(currentPageNumber + 1)
                setDisciplines(data)
            })
            .catch(err => {
                console.log(err.response)
            })
    }

    const handlePageIndex = (index: number) => {
        // getPagination(`api/connections?page=${index}&limit=${pageLimit}&search=${inputSearch}&fromDate=${startDate}&toDate=${endDate}${status !== '' ? '&status=' + status : ''}`, token)
        getPagination(`api/disciplines?page=${index}&isActionRequired=true&limit=${pageLimit}&fromDate=${startDate}&toDate=${endDate}${teamId !== 0 ? '&teamId=' + teamId : ''}${supervisorId !== 0 ? '&creatorId=' + supervisorId : ''}&sort=${sort.order === 'DESC' ? '-' : ''}${sort.field}`, token)
            .then(({ data }) => {
                setCurrentPageNumber(index)
                setDisciplines(data)
            })
            .catch(err => {
                console.log(err.response)
            })
    }

    const handlePageLimitChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setPageLimit(parseInt(e.target.value))
    }

    const handleCancelDelete = () => {
        setShowDeleteDialog(false)
    };

    const handleConfirmDelete = (errorToast: Function, successToast: Function) => {
        setShowDeleteDialog(false);
        if (selectedDiscipline) {
            removeDiscipline(selectedDiscipline.id, token)
            .then(() => {
                successToast();
                handlePageIndex(currentPageNumber);
            })
            .catch(err => {
                console.log(err);
                errorToast();
            });
        }
    };

    const removeSelectedDiscipline = (discipline: DisciplineType) => {
        setShowDeleteDialog(true);
        setSelectedDiscipline(discipline);
    };

    const handleViewDiscipline = (discipline: DisciplineType) => {
        setSelectedDiscipline(discipline)
        setShowNewDiscipline(true);
        console.log('want to view discipline', discipline);
    };

    useEffect(() => {
        let abortController = new AbortController();
        // getPagination(`api/connections?limit=${pageLimit}&search=${inputSearch}&fromDate=${startDate}&toDate=${endDate}${status !== '' ? '&status=' + status : ''}`, token)
        getPagination(`api/disciplines?limit=${pageLimit}&isActionRequired=true&fromDate=${startDate}&toDate=${endDate}${teamId !== 0 ? '&teamId=' + teamId : ''}${supervisorId !== 0 ? '&creatorId=' + supervisorId : ''}&sort=${sort.order === 'DESC' ? '-' : ''}${sort.field}`, token)
            .then(({ data }) => {
                setDisciplines(data)
            })
            .catch(err => {
            })

        return () => {
            abortController.abort();
        }
    }, [supervisorId, teamId, token, pageLimit, endDate, startDate, user.roles, sort])

    return (
        <>
            <div className={`card ${className}`}>
                {/* begin::Header */}
                <div className='card-header border-0 pt-5'>
                    <div className='pt-7 d-flex w-200px align-items-center' id='kt_chat_contacts_header'>
                        <select
                            className="form-select py-2 w-80px text-end"
                            data-control="select2"
                            data-placeholder="Select an option"
                            value={pageLimit}
                            onChange={handlePageLimitChange}
                        >
                            <option value="5">5</option>
                            <option value="12">12</option>
                            <option value="24">24</option>
                            <option value="100">100</option>
                        </select>
                        <span className='ms-2'>rows per page</span>
                    </div>


                    <div
                        className='card-toolbar'
                        data-bs-toggle='tooltip'
                        data-bs-placement='top'
                        data-bs-trigger='hover'
                        title='Click to filter results'
                    >
                        <div className='d-flex align-items-center py-1 me-4'>
                            <div className='me-4'>
                                <FilterButton
                                  toggle='modal'
                                  target='#discipline_filter_modal' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='card-body py-3'>
                    <a
                        ref={viewModalRef}
                        href='#'
                        className='d-none'
                        data-bs-toggle='modal'
                        data-bs-target='#discipline_view_modal'
                    >
                        View
                    </a>
                    <div className='table-responsive'>
                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                            <thead>
                                <tr className='fw-bolder text-muted'>
                                    <th className='min-w-150px'>
                                        <button
                                            className='p-0'
                                            type="button"
                                            onClick={() => {
                                                setSort({
                                                    field: 'firstName',
                                                    order: (sort.field !== 'firstName' || sort.order === 'DESC') ? 'ASC' : 'DESC',
                                                })
                                            }}
                                        >
                                            Member / Supervisor
                                            {sort.field === 'firstName' &&
                                                <span className='ms-3'>{sort.order === 'DESC' ? "↓" : "↑"}</span>
                                            }
                                        </button>
                                    </th>
                                    <th className='min-w-140px'>
                                        <button
                                            className='p-0'
                                            type="button"
                                            onClick={() => {
                                                setSort({
                                                    field: 'date',
                                                    order: (sort.field !== 'date' || sort.order === 'ASC') ? 'DESC' : 'ASC',
                                                })
                                            }}
                                        >
                                            Date
                                            {sort.field === 'date' &&
                                                <span className='ms-3'>{sort.order === 'DESC' ? "↓" : "↑"}</span>
                                            }
                                        </button>
                                    </th>
                                    <th className='min-w-120px'>
                                        Department
                                    </th>
                                    <th className='min-w-120px'>
                                        Step
                                    </th>
                                    <th className='min-w-120px'>
                                        Type
                                    </th>
                                    <th className='min-w-120px'>
                                        Status
                                    </th>
                                    <th className='min-w-100px text-end'>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {disciplines.items.length > 0 ?
                                    (disciplines.items as DisciplineType[]).map((discipline, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>
                                                    <div className='d-flex align-items-center'>
                                                        <div className='symbol symbol-45px me-5 symbol-circle'>
                                                            <img src={(discipline.employee?.avatarUrl == null || discipline.employee.avatarUrl?.includes('media/icons/duotune/general/create-team-icon')) ? toAbsoluteUrl('/media/avatars/blank.png') : discipline.employee.avatarUrl} alt='avatar' />
                                                        </div>
                                                        <div className='d-flex justify-content-start flex-column'>
                                                            <Link
                                                                to={`/team-member/${discipline.team?.id}/show/${discipline.employee?.id}`}
                                                                className='text-dark fw-bolder text-hover-primary fs-6'
                                                            >
                                                                {discipline.employee?.firstName} {discipline.employee?.lastName}
                                                            </Link>
                                                            <span className='text-muted fw-bold fs-7'>{discipline.creator?.firstName} {discipline.creator?.lastName}</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <span className='text-muted fw-bold d-block fs-7'>
                                                        {discipline.date}
                                                    </span>
                                                </td>
                                                <td className='text-end'>
                                                    <div className='d-flex flex-column w-100 me-2'>
                                                        <div className='d-flex flex-stack mb-2'>
                                                            <span className='text-muted me-2 fs-7 fw-bold'>{discipline.team?.department}</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='text-start'>
                                                    <div className='d-flex flex-column w-100 me-2'>
                                                        <div className='d-flex flex-stack mb-2'>
                                                            <span className='me-2 fs-7 fw-bold px-3 rounded'
                                                                style={{
                                                                    color: discipline.step.stepColor
                                                                }}
                                                            >
                                                                {discipline.step.name}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='text-start'>
                                                    <div className='d-flex flex-column w-100 me-2'>
                                                        <div className='d-flex flex-stack mb-2'>
                                                            <span className='me-2 fs-7 fw-bold px-3 rounded'>
                                                                {discipline.step.type}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='text-start'>
                                                    <div className='d-flex flex-column w-100 me-2'>
                                                        <div className='d-flex flex-stack mb-2'>
                                                            <span className='me-2 fs-7 fw-bold px-3 rounded'>
                                                                {DisciplineStatusForHumansEnum[discipline.status]}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='text-end'>
                                                    <div className="d-flex align-items-center justify-content-end">
                                                        <button
                                                            type='button'
                                                            className='btn btn-light btn-sm me-2'
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#new-discipline-modal"
                                                            onClick={() => { handleViewDiscipline(discipline) }}
                                                        >
                                                            View
                                                        </button>
                                                        {user.roles?.includes('SuperAdmin') &&
                                                                                                                <button
                                                                                                                type='button'
                                                                                                                className='btn btn-danger btn-sm me-2'
                                                                                                                onClick={() => { removeSelectedDiscipline(discipline) }}
                                                                                                            >
                                                                                                                Delete
                                                                                                            </button>
                                                        }
                                                        {/* {((!!user.roles?.includes('Supervisor') && (discipline.status === 'Pending' || connection.status === 'ChangeRequested')) ||
                                                        !!user.roles?.includes('SuperAdmin')) &&  */}
                                                        {/*<DropdownUserActions*/}
                                                        {/*    viewModalId='discipline_view_modal'*/}
                                                        {/*    editModalId='discipline_edit_modal'*/}
                                                        {/*    isDiscipline={true}*/}
                                                        {/*    disciplineStatus={DisciplineStatusForUserEnum[discipline.status]}*/}
                                                        {/*    handleSelectedIndex={() => { }}*/}
                                                        {/*    handleSelectedConnection={() => { setSelectedDiscipline(discipline) }}*/}
                                                        {/*    removeSelectedConnection={() => removeSelectedDiscipline(discipline)}*/}
                                                        {/*/>*/}
                                                    </div>
                                                    {/* }
                                                    {(!!user.roles?.includes('Supervisor') && connection.status !== 'Pending' && connection.status !== 'ChangeRequested') &&
                                                        <button
                                                            type='button'
                                                            className='btn btn-light btn-sm ms-auto'
                                                            onClick={() => {
                                                                setSelectedConnection(connection)
                                                                viewModalRef.current?.click()
                                                            }}
                                                        >
                                                            View
                                                        </button>
                                                    } */}
                                                </td>
                                            </tr>
                                        )
                                    }) :
                                    <tr><td colSpan={7} className='text-center'>No Data</td></tr>
                                }
                            </tbody>
                        </table>
                        <div className='d-flex flex-stack flex-wrap pb-5'>
                            <div className='fs-6 fw-bold text-gray-700'></div>

                            <Pagination
                                data={disciplines}
                                handlePrevious={handlePrevious}
                                handleNext={handleNext}
                                handlePageIndex={handlePageIndex}
                            />
                        </div>
                    </div>
                </div>

                <CorrectiveActionModal
                  refresh={() => {}}
                  show={showNewDiscipline}
                  disciplineId={selectedDiscipline?.id}
                  employee={(selectedDiscipline?.employee as unknown as Employee)}
                />
            </div>
            <ConnectionListFilterModal
                applyFilter={applyFilterView}
                modalId='discipline_filter_modal'
            />
            {showDeleteDialog && (
            <SkeDialogDelete
                onCancel={handleCancelDelete}
                onConfirm={handleConfirmDelete}
                successMessage='Corrective Action deleted'
                message='Are you sure you want to delete this corrective action?'
            />
            )}
        </>
    )
}

export default DisciplinesList
